<template>
  <div>
    <img
      v-if="languageSelected === 'es'"
      src="../../../assets/images/plugin-page-es.png"
      width="100%"
      class="swiper-lazy"
    />
    <img
      v-else
      src="../../../assets/images/plugin-page-en.png"
      width="100%"
      class="swiper-lazy"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>
